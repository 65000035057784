
<div>
  <div class="mb-5">
    <div class="w-100 d-flex justify-content-between mb-4">
      <cvt-button
        class="rounded-lg"
        color="dark"
        text=""
        icon="cvt-icon-arrow-left-05"
        @click="changeViewMode"
      />

      <cvt-button
        class="rounded-lg"
        color="dark"
        :text="'Save'"
        @click="saveBrandingDataToFirebase"
      />
    </div>

    <cvt-ent-dashboard-tabs
      :active-tab="activeTabIdx"
      :tabs-array="tabs"
      :usage="'marketplace'"
      :nav-tab-width="'100%'"
      :nav-tab-body-width="'100%'"
      @tabClicked="(idx) => onTabClicked(idx)"
    >
      <div v-if="activeTabIdx === 0">
        <div class="mx-5 d-flex mt-5">
          <!-- left section -->
          <div
            class="d-flex flex-column"
            style="width: 360px; margin-right: 60px"
          >
            <div class="mb-4">
              <p style="font-weight: 700">{{ $t('element.property.branding.name.label') }}</p>

              <cvt-input
                :value="brandingName"
                type="text"
                :clearable="false"
                :required="true"
                :maxlength="50"
                :placeholder="$t('element.property.branding.name.label')"
                :validation-status="nameInvalid ? 'invalid' : 'valid'"
                :valid-message="$t('element.property.branding.name.valid')"
                :invalid-message="$t('element.property.branding.name.invalid')"
                @input="(e) => onChangeBrandingName(e)"
              />
            </div>
            <div class="mb-4 branding-logo-container">
              <p style="font-weight: 700">{{ $t('element.property.color.palette.upload.logo.label') }}</p>
              <div
                v-if="selectedBrandLogo"
                class="branding-logo-wrapper"
              >
                <img
                  :src="selectedBrandLogo"
                  alt="brand-logo"
                  class="branding-logo-image"
                />
              </div>
              <upload-dropzone
                ref="uploadDropzone"
                class="branding-upload-dropzone-style"
                :label="$t('element.upload.image.label.drag')"
                :btn-label="$t('element.upload.image.label.computer')"
                :hide-gallery-button="true"
                :image-only="true"
                :size-limit="5"
                :size-limit-to-top="false"
                @complete="uploadComplete"
              />
            </div>
            <div>
              <url-input
                id="site_favicon"
                v-model="faviconUrl"
                class="branding_url_input"
                :label="$t('settings.favicon_link.label')"
                :allowed-protocols="['http', 'https']"
              />
            </div>
          </div>

          <!-- Right section -->
          <div class="branding-right-section">
            <div>
              <p style="font-weight: 700">{{ $t('element.property.color.palette.label') }}</p>
              <div>
                <colors-alt
                  :alpha="false"
                  :mode="mode"
                  @apply-color="(value) => {}"
                />
              </div>
            </div>

            <div>
              <!-- Font Family, Font Size & Font Default Text Color -->
              <cvt-collapse
                class="row"
                :accordion="true"
                :prepend-chevron="true"
              >
                <cvt-collapse-item
                  :title="$t('element.property.typography')"
                  name="properties"
                  :initial-active="true"
                >
                  <div
                    v-for="style in fontStyles"
                    :key="style.label"
                    class="mb-3"
                  >
                    <div
                      class="bg-light border rounded py-3 px-3 row align-items-center"
                    >
                      <div class="row mr-2">
                        <div class="col-12">
                          <default-color-selector
                            :value="style.format?.defaultTextColor"
                            @input="updateDefaultTextColor(style, $event)"
                          />
                        </div>
                      </div>
                      <div>
                        <p class="text-secondary text-capitalize">
                          <small>{{ style.tag }}</small>
                        </p>
                        <p
                          class="mt-4"
                          :style="style.format?.toStyle()"
                          v-text="$t(`setup.font.${snakeCase(style.label)}`)"
                        />
                      </div>
                    </div>

                    <div class="row flex-grow-1 mt-2">
                      <div
                        class="d-flex justify-content-start align-items-center mb-3"
                      >
                        <div class="col-4">
                          <font-picker
                            :value="style.format?.font"
                            @input="updateFontFamily(style, $event)"
                          />
                        </div>
                        <div class="col-2">
                          <font-size-selector
                            :value="style.format?.size"
                            @input="updateFontSize(style, $event)"
                          />
                        </div>
                        <div class="col-6">
                          <font-weight-selector
                            :value="style.format?.weight"
                            @input="updateFontWeight(style, $event)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </cvt-collapse-item>
              </cvt-collapse>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="activeTabIdx === 1">
        <div class="mx-5 mt-5 pb-5">
          <page-tracking />
        </div>
      </div>
    </cvt-ent-dashboard-tabs>
  </div>
</div>
