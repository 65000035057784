<template>
  <div>
    <div class="mb-5">
      <div class="w-100 d-flex justify-content-between mb-4">
        <cvt-button
          class="rounded-lg"
          color="dark"
          text=""
          icon="cvt-icon-arrow-left-05"
          @click="changeViewMode"
        />

        <cvt-button
          class="rounded-lg"
          color="dark"
          :text="'Save'"
          @click="saveBrandingDataToFirebase"
        />
      </div>

      <cvt-ent-dashboard-tabs
        :active-tab="activeTabIdx"
        :tabs-array="tabs"
        :usage="'marketplace'"
        :nav-tab-width="'100%'"
        :nav-tab-body-width="'100%'"
        @tabClicked="(idx) => onTabClicked(idx)"
      >
        <div v-if="activeTabIdx === 0">
          <div class="mx-5 d-flex mt-5">
            <!-- left section -->
            <div
              class="d-flex flex-column"
              style="width: 360px; margin-right: 60px"
            >
              <div class="mb-4">
                <p style="font-weight: 700">{{ $t('element.property.branding.name.label') }}</p>

                <cvt-input
                  :value="brandingName"
                  type="text"
                  :clearable="false"
                  :required="true"
                  :maxlength="50"
                  :placeholder="$t('element.property.branding.name.label')"
                  :validation-status="nameInvalid ? 'invalid' : 'valid'"
                  :valid-message="$t('element.property.branding.name.valid')"
                  :invalid-message="$t('element.property.branding.name.invalid')"
                  @input="(e) => onChangeBrandingName(e)"
                />
              </div>
              <div class="mb-4 branding-logo-container">
                <p style="font-weight: 700">{{ $t('element.property.color.palette.upload.logo.label') }}</p>
                <div
                  v-if="selectedBrandLogo"
                  class="branding-logo-wrapper"
                >
                  <img
                    :src="selectedBrandLogo"
                    alt="brand-logo"
                    class="branding-logo-image"
                  />
                </div>
                <upload-dropzone
                  ref="uploadDropzone"
                  class="branding-upload-dropzone-style"
                  :label="$t('element.upload.image.label.drag')"
                  :btn-label="$t('element.upload.image.label.computer')"
                  :hide-gallery-button="true"
                  :image-only="true"
                  :size-limit="5"
                  :size-limit-to-top="false"
                  @complete="uploadComplete"
                />
              </div>
              <div>
                <url-input
                  id="site_favicon"
                  v-model="faviconUrl"
                  class="branding_url_input"
                  :label="$t('settings.favicon_link.label')"
                  :allowed-protocols="['http', 'https']"
                />
              </div>
            </div>

            <!-- Right section -->
            <div class="branding-right-section">
              <div>
                <p style="font-weight: 700">{{ $t('element.property.color.palette.label') }}</p>
                <div>
                  <colors-alt
                    :alpha="false"
                    :mode="mode"
                    @apply-color="(value) => {}"
                  />
                </div>
              </div>

              <div>
                <!-- Font Family, Font Size & Font Default Text Color -->
                <cvt-collapse
                  class="row"
                  :accordion="true"
                  :prepend-chevron="true"
                >
                  <cvt-collapse-item
                    :title="$t('element.property.typography')"
                    name="properties"
                    :initial-active="true"
                  >
                    <div
                      v-for="style in fontStyles"
                      :key="style.label"
                      class="mb-3"
                    >
                      <div
                        class="bg-light border rounded py-3 px-3 row align-items-center"
                      >
                        <div class="row mr-2">
                          <div class="col-12">
                            <default-color-selector
                              :value="style.format?.defaultTextColor"
                              @input="updateDefaultTextColor(style, $event)"
                            />
                          </div>
                        </div>
                        <div>
                          <p class="text-secondary text-capitalize">
                            <small>{{ style.tag }}</small>
                          </p>
                          <p
                            class="mt-4"
                            :style="style.format?.toStyle()"
                            v-text="$t(`setup.font.${snakeCase(style.label)}`)"
                          />
                        </div>
                      </div>

                      <div class="row flex-grow-1 mt-2">
                        <div
                          class="d-flex justify-content-start align-items-center mb-3"
                        >
                          <div class="col-4">
                            <font-picker
                              :value="style.format?.font"
                              @input="updateFontFamily(style, $event)"
                            />
                          </div>
                          <div class="col-2">
                            <font-size-selector
                              :value="style.format?.size"
                              @input="updateFontSize(style, $event)"
                            />
                          </div>
                          <div class="col-6">
                            <font-weight-selector
                              :value="style.format?.weight"
                              @input="updateFontWeight(style, $event)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </cvt-collapse-item>
                </cvt-collapse>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="activeTabIdx === 1">
          <div class="mx-5 mt-5 pb-5">
            <page-tracking />
          </div>
        </div>
      </cvt-ent-dashboard-tabs>
    </div>
  </div>
</template>

<script>
import ColorsAlt from './ColorsAlt.vue'
import UploadDropzone from '../../asset-manager/UploadDropzone.vue'
import PageTracking from '../../editor/left-side-menu/PageTracking.vue'
import DefaultColorSelector from './ColorSelector.vue'
import FontPicker from '../../common/FontPicker.vue'
import FontSizeSelector from '../../common/FontSizeSelector.vue'
import FontWeightSelector from '../../common/FontWeightSelector.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import { BRAND_MODE_LIST } from '@/js/common/constants/constants'

import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'

export default {
  name: 'CreateBrand',
  components: {
    FontSizeSelector,
    ColorsAlt,
    UploadDropzone,
    FontWeightSelector,
    FontPicker,
    DefaultColorSelector,
    PageTracking,
  },
  data() {
    return {
      mode: 'LIGHT',
      tabs: [
        'Branding Elements',
        'Javascript Tracking (Facebook Pixel / Google Analytics)',
      ],
      activeTabIdx: 0,
      brandingData: [],
      loadingBrandingData: false,
      selectedBrandLogo: null,
    }
  },
  computed: {
    ...mapState({
      brandingName: (state) =>
        state['marketplace-branding:create'].brandingName,
      _faviconUrl: (state) => state['marketplace-branding:create'].faviconUrl,
      brandLogo: (state) => state['marketplace-branding:create'].brandLogo,
      typography: (state) => state['marketplace-branding:create'].typography,
    }),
    ...mapGetters({
      fontStyles: getters.MP_GET_FONT_STYLES,
    }),
    faviconUrl: {
      get() {
        return this._faviconUrl
      },
      set(url) {
        this.setFaviconUrl(url)
      },
    },
    nameInvalid() {
      return !this.brandingName || this.brandingName.length < 3
    },
  },
  methods: {
    ...mapActions({
      saveToFirebase: actions.MP_SAVE_BRANDING_DATA_TO_FIREBASE,
      setViewMode: actions.MP_SET_MANAGER_MODE,
      initBrandManager: actions.MP_INIT_BRAND_MANAGER,
    }),
    ...mapMutations({
      clearBrandingData: mutations.MP_RESET_STATE,
      setBrandingName: mutations.MP_SET_BRANDING_NAME,
      setBrandLogo: mutations.MP_SET_BRAND_LOGO,
      setFaviconUrl: mutations.MP_SET_FAVICON_URL,
      setTypography: mutations.MP_SET_TYPOGRAPHY,
    }),
    snakeCase,
    onTabClicked(idx) {
      this.activeTabIdx = idx
    },
    updateFontFamily({ style, format }, font) {
      format.font = font;
      this.setTypography(style, format)
    },

    updateFontSize({ style, format }, size) {
      format.size = size
      this.setTypography(style, format)
    },

    updateFontWeight({ style, format }, weight) {
      format.weight = weight
      this.setTypography(style, format)
    },

    // updateSelectedFontWeights({ style, format }, weights) {
    //   format.selectedWeights = weights
    //   this.setTypography(style, format)
    // },

    updateDefaultTextColor({ style, format }, defaultTextColor) {
      format.defaultTextColor = defaultTextColor
      this.setTypography(style, format)
    },

    uploadComplete(assets) {
      const image = assets.pop()
      this.selectedBrandLogo = image
      this.setBrandLogo(image)
    },

    onChangeBrandingName({ target }) {
      this.setBrandingName(target.value)
    },

    changeViewMode() {
      this.setViewMode(BRAND_MODE_LIST)
    },
    async saveBrandingDataToFirebase() {
      // Do something data validation before saving
      const saved = await this.saveToFirebase()
      if (saved) {
        // move back to list view
        this.clearBrandingData()
        this.initBrandManager()
        this.changeViewMode()
        this.$message({
          type: 'info',
          message: 'Branding data saved successfully',
        })
      } else {
        this.$message({
          type: 'error',
          message: 'Unable to save branding data',
        })
      }
    },
  },
}
</script>

<style scoped>
.branding-logo-container {
  position: relative;
  width: 100%;
}
.branding-logo-wrapper {
  height: 140px;
  width: 140px;
  position: absolute;
  margin: 0 auto;
  border: 3px solid #e0e0e0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  background-color: #e7e7e7;
}

.branding-logo-image {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
</style>
